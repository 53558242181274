import React, { useContext, useState, useHook, useEffect } from 'react';
import { Icon, Step, Grid, Header, Segment } from 'semantic-ui-react'
import { createApplication, savedInfoPaymentMutationGql } from '../../store/schemas/location.jsx'
import { APPLICATION_PUBLIC } from '../../utils/constants.js'
import { Link } from 'react-router-dom';
import './Application.css'
import Begin from './Begin.js'
import ContactUs from './ContactUs.js'
import Lived from './Lived.js'
import Occupants from './Occupants.js'
import Personal from './Personal.js'
import Income from './Income.js'
import Questions from './Questions.js'
import Documents from './Documents.js'
import moment from "moment"; 
import Fees from './Fees.js'
import Review from './Review.js'
import PostSubmit from './PostSubmit.js'
import { getClient } from "../../init-apollo-googleFn";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css'

// const toLocClient = getClient(APPLICATION_RENTER);
const paymentsClient = getClient("PAYMENTS_URL");

function HomeApplication(props) {

    const [appStep, setAppStep] = useState(1);
    const [loader, setLoader] = useState(false);
    const [dataObject, setDataObject] = useState({

    });
    const notifyTrue = () => toast.success("Payment processed! Please check your details and submit your application.");
    const notifyYay = () => toast.success("Application submitted!");
    const notifyFalse = () => toast.error("Payment failed. Please check your payment and contact details for accuracy.");
    const notifyFail = () => toast.error("Something went wrong when submitting, please wait and try in a few minutes.");

    useEffect(() => {
        const itemToSave = JSON.stringify(dataObject)
        if (dataObject && Object.keys(dataObject).length !== 0) {
            localStorage.setItem('step', appStep)
            localStorage.setItem('dataObject', itemToSave)
        }
    }, [dataObject]);

    useEffect(() => {
        console.log(dataObject, props)
    }, [dataObject]);

    useEffect(() => {
        renderView()
    }, [appStep]);

    useEffect(() => {
        const queryString = window.location.pathname.split("/")[2];
        const queryStringUnit = props.match.params
        const Obj = {
            unit: queryStringUnit.id,
            location: queryString,
            name: queryStringUnit.name,
            unitName: queryStringUnit.unit,
        }
        setDataObject(prevState => ({ ...prevState, Info: Obj }))
    }, [props]);

    useEffect(() => {
        if (performance.navigation.type === 1) {
            const savedData = localStorage.getItem('dataObject')
            const savedStep = localStorage.getItem('step')
            setAppStep(Number(savedStep))
            setDataObject(JSON.parse(savedData))
        } else {
            console.log("This page is not reloaded");
        }
    }, []);



    const createApplication = async () => {
        setLoader(true)
        const queryString = window.location.pathname.split("/")[2];
        const queryStringUnit = window.location.href;
        var n = queryStringUnit.lastIndexOf('/');
        var result = queryStringUnit.substring(n + 1);
        const locObject = {
            unit: result,
            location: queryString
        }
        const formData = new FormData();
        formData.append("", dataObject.Page8 && dataObject.Page8.Id)
        formData.append("", dataObject.Page8 && dataObject.Page8.Proof)
        formData.append("", dataObject.Page8 && dataObject.Page8.Other)
        formData.append(
            "query",
            `mutation {
            createApplication(input: {
                application:{
                    Info: ${JSON.stringify(JSON.stringify(locObject))},
                    Page1: ${JSON.stringify(moment(dataObject.Page1).format("MM/DD/YYYY"))}, 
                    Page2: ${JSON.stringify(JSON.stringify(dataObject.Page2))},
                    Page3: ${JSON.stringify(JSON.stringify(dataObject.Page3))}, 
                    Page4: ${JSON.stringify(JSON.stringify(dataObject.Page4))}, 
                    Page5: ${JSON.stringify(JSON.stringify(dataObject.Page5))}, 
                    Page6: ${JSON.stringify(JSON.stringify(dataObject.Page6))},
                    Page7: ${JSON.stringify(JSON.stringify(dataObject.Page7))},
                    
                }
            }){
              response
            }}`
        )
        console.log(formData)
        if (formData) {
            await fetch(APPLICATION_PUBLIC, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *client
                body: formData, // body data type must match "Content-Type" header
            })
                .then((result) => {
                    notifyYay()
                    setLoader(false)
                    setAppStep(11)
                })
                .catch((error) => {
                    notifyFail()
                    setLoader(false)
                });
        }
    }
    

    const renderView = () => {
        switch (appStep) {
            case 1:
                return <Begin setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} doc={props.local.location}/>;
            case 2:
                return <ContactUs setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} />;
            case 3:
                return <Lived setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} />;
            case 4:
                return <Occupants setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} />;
            case 5:
                return <Personal setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} />;
            case 6:
                return <Income setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} createApplication={createApplication} />;
            case 7:
                return <Questions setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} />;
            case 8:
                return <Documents setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} />;
            case 9:
                return <Fees setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} createApplication={createApplication} notifyTrue={notifyTrue} notifyFalse={notifyFalse} />;
            case 10:
                return <Review setAppStep={setAppStep} setDataObject={setDataObject} dataObject={dataObject} createApplication={createApplication} loader={loader} setLoader={setLoader} />;
            case 11:
                return <PostSubmit setAppStep={setAppStep} createApplication={createApplication} />;
        }
    }

    return (
        <>
            <div className="home-application-container" >
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Segment><Header>{dataObject.Info && dataObject.Info.name} - #{dataObject.Info && dataObject.Info.unitName}</Header></Segment>
                <Grid columns={"2"} >
                    {
                        appStep !== 11 &&
                        <Grid.Column width="2">
                            <Step.Group vertical>
                                <Step active={appStep == 1}>
                                    <Step.Content onClick={() => setAppStep(1)}>
                                        <Step.Title>Before you begin</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page1} active={appStep == 2}>
                                    <Step.Content onClick={() => setAppStep(2)}>
                                        <Step.Title>Contact Info</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page2} active={appStep == 3}>
                                    <Step.Content onClick={() => setAppStep(3)}>
                                        <Step.Title>Where you've lived</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page3} active={appStep == 4}>
                                    <Step.Content onClick={() => setAppStep(4)}>
                                        <Step.Title>Occupants</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page4} active={appStep == 5}>
                                    <Step.Content onClick={() => setAppStep(5)}>
                                        <Step.Title>Personal information</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page5} active={appStep == 6}>
                                    <Step.Content onClick={() => setAppStep(6)}>
                                        <Step.Title>Your Income</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page6} active={appStep == 7}>
                                    <Step.Content onClick={() => setAppStep(7)}>
                                        <Step.Title>Questions</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page7} active={appStep == 8}>
                                    <Step.Content onClick={() => setAppStep(8)}>
                                        <Step.Title>Documents</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step disabled={!dataObject.Page8} active={appStep == 9}>
                                    <Step.Content onClick={() => setAppStep(9)}>
                                        <Step.Title>Fees</Step.Title>
                                    </Step.Content>
                                </Step>

                                <Step>
                                    <Step.Content onClick={() => setAppStep(10)}>
                                        <Step.Title>Review</Step.Title>
                                    </Step.Content>
                                </Step>
                            </Step.Group>
                        </Grid.Column>
                    }
                    <Grid.Column width="13">
                        {renderView()}
                    </Grid.Column>
                </Grid>

            </div>
        </>
    );
}

export default HomeApplication