import React, { Component } from 'react';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import Contact from './components/contact';
import HomeV10 from './components/home-v11';
import ProductDetails from './components/product-details';
import ShopGrid from './components/shop-grid';
import TourPopup from '../../../shared_components/TourPopup';
import '../public/assets/css/responsive.css';
import '../public/assets/css/style.css';
import '../public/assets/css/custom.css';
import '../public/assets/css/custom.css';
import '../public/assets/css/sections/_error_404.css';
import '../public/assets/css/sections/_portfolio.css';
import '../public/assets/css/elements/_portfoli.css';
import '../public/assets/sass/style.scss';
import '../Documentation/css/plugins.css';
import '../Documentation/css/style.css';
import HomeApplication from '../../../shared_components/Application/index.js';
import '../Documentation/css/default.css';


function Microsite_3(props) {
    const PrivateRoute = ({ component: Component, isServiceProf, ...rest }) => {
        return (
            <Route
                {...rest}
                render={(props) =>
                    <Component {...props}  {...rest} />
                }
            />
        );
    };
    return (
        <Router>
            <HashRouter basename="/">
                <div>
                    <Switch>
                    <Route exact path="/" component={HomeV10} />
                        <Route path="/units" component={ ShopGrid } />
                        <Route path="/unit-details" component={ ProductDetails } />
                        <Route path="/contact" component={ Contact } />
                        <PrivateRoute path="/application/:id/:name/:unit" props={props} component={HomeApplication} />
                    </Switch>
                </div>
            </HashRouter>
            <TourPopup background="#fda94f" submitStyle={{ backgroundColor: '#fda94f', color: 'white', borderRadius: '3px' }} />
        </Router>
    )
}



export default Microsite_3;
