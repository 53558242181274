import React, { useContext, useEffect, useState } from 'react';
import sqftIcon from '../../assets/img/icons/7.png';
import { Context } from '../../../../AppContext';
import placeholderUnitPic from '../../../../assets/undraw/undraw_apartment.png';
import MapContainer from '../../../../shared_components/google-map';
import { Loader } from 'semantic-ui-react';
import { leadSchema } from '../../../../Validations/UserValidations';
import { MICROSITE_INFO } from '../../../../utils/constants';
import { createLeadGql } from '../../../../store/schemas/location';
import { Link } from 'react-router-dom';
import { getClient } from '../../../../init-apollo-googleFn';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { TextField } from '../../../../shared_components/TextField';
import 'react-toastify/dist/ReactToastify.css';
import placeHolderPlanPic from '../../../../assets/undraw/undraw_plan.png';
import Carousel  from '../../../../shared_components/Carousel';

function PropertyDetails() {
  const { location, locationId, locationAmenities } = useContext(Context);
  const contact = JSON.parse(location.contact);
  const selectedUnit = JSON.parse(localStorage.getItem("selectedUnit"));
  const photos = selectedUnit&&selectedUnit.node.photos;
  const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
  const address = JSON.parse(orgDetails.address);
  const [ageCheck, setAgeCheck] = useState(false);
  const [mutateReady, setMutateReady] = useState(false);
  const floorPlanPic = (selectedUnit&&selectedUnit.floorPlan&&selectedUnit.floorPlan.node.photos !== "[]" && selectedUnit.floorPlan.node.photos !== '""') ? selectedUnit.floorPlan.node.photos.slice(4, selectedUnit.floorPlan.node.photos.length -4) : placeHolderPlanPic;
  const [toastMessage, setToastMessage] = useState(null);
  const [loader, setLoader] = useState(false);

	const micrositeClient = getClient(MICROSITE_INFO);

  const createLead = (locationId, obj) => {
    try {
      setLoader(true);
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Prospect",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: selectedUnit.node.id,
            }
          }
        }
      }).then(response => {
        setLoader(false);
				if (response.data.createLead.response === null && response.data.createLead.lead.id) {
          setToastMessage(toast.success("We've received your contact info. An agent will email you soon."));
				} else if (response.data.createLead.response !== null && response.data.createLead.lead.id) {
          setToastMessage(toast.warn("This email is already associated with this unit."));
				} else {
          setToastMessage(toast.error("We were unable to process your request. We may already have your email associated with the selected unit."));
				}
      }).catch(e => console.log(e));
    }
    catch(e) {
      setLoader(false);
      console.log(e);
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if(mutateReady) {
      createLead(locationId);
      setMutateReady(false);
    }
  }, [mutateReady])

  useEffect(() => {
		return toastMessage;
	}, [toastMessage]);

  const leadFormSubmit = async(values) => {
    const isValid = await leadSchema.isValid();
		createLead(locationId, values);
  }
  /* eslint-enable */

  return(
    orgDetails !== undefined &&
    <>
      <div className="property-details-area" id="test">
        <ToastContainer position="bottom-center"/>
        <div className="bg-gray pd-top-100 pd-bottom-60">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-8">
                  { photos&&photos.length > 1 ?
                    <Carousel photos={photos} />
                  :
                    <div className="item">
                      <div className="thumb">
                        <img
                          src={photos&&photos.length === 1 ? photos[0] : placeholderUnitPic}
                          style={{
                            height: "643.5px",
                            width: "990px",
                            borderRadius: "6px",
                            paddingBottom: "12px",
                            marginBottom: "20px"
                          }}
                          alt="apartment"/>
                      </div>
                    </div>
                  }
                <div className="property-details-slider-info mb-5">
                  <h3 style={{fontSize: "22px"}}>Unit: {selectedUnit&&selectedUnit.node.number} <span style={{position: "absolute", right: "0px"}}>${selectedUnit&&selectedUnit.node.price}/month</span></h3>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div style={{padding: '16px 30px 40px 16px'}} className="widget widget-owner-info mt-lg-0 mt-5 bg-gray">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      unit: selectedUnit.node.number
                    }}
                    validationSchema={leadSchema}
                    onSubmit={(values, {resetForm}) => {
                      leadFormSubmit(values);
                      resetForm();
                    }}
                  >
                    {formik => (
                      <Form id="contact" style={{width: '220px', minHeight: '534px'}} className="contact-form-wrap">
                        <h4> Contact Us</h4>
                        <TextField style={{marginBottom: '6px'}} label="First Name" name="firstName" type="text" />
                        <TextField style={{marginBottom: '6px'}} label="Last Name" name="lastName" type="text" />
                        <TextField style={{marginBottom: '6px'}} label="Email" name="email" type="email" />
                        <TextField style={{marginBottom: '6px'}} label="Phone Number" name="phoneNumber" type="text" />
                        <label style={{textAlign: 'center'}}>Are you at least 18 years of age?</label>
                        <input
                          onClick={()=>setAgeCheck(!ageCheck)}
                          type="checkbox"
                          style={{margin: '0 auto 2px auto', display: 'block', width: '12.31px'}}
                        />
                        <div className="text-center">
                          {loader ?
                            <div style={{transform: 'scale(0.4)'}}>
                              <Loader style={{margin: '0 auto'}}/>
                            </div>
                          :
                            <button disabled={!ageCheck} style={{backgroundColor: '#fda94f', color: 'white', borderRadius: '4px', marginTop: '10px', width: '160px'}} className="btn" type="click">Submit</button>
                          }
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {/* <aside className="sidebar-area"> */}
						{/* <div id="contact" className="contact-form-wrap contact-form-bg"> */}
								<div className="btn-wrap text-center"> 
							<h4> Application</h4>
                    <Link 
                      className='appointment'
                      to={`/application/${selectedUnit.node.id}/${location.name}/${selectedUnit.node.number}` }
                      state={{ selectedUnit }}
                      style={{backgroundColor: '#fda94f', color: 'white', borderRadius: '4px', marginTop: '10px', width: '295px'}}  type="click"
                    >
                      Apply Now
                    </Link>
									{/* <a target="_blank" href={`https://dev.renter.leasera.com/#/applicationForm/${locationId}/${selectedUnit.node.id}/${selectedUnit.node.number}`} disabled={!ageCheck}  */}
								</div>
							{/* </div> 
						{/* </aside> */}
                  <div className="contact-info" style={{width: '280px', paddingLeft: '6px', paddingBottom: '0'}}>
                    <h5>Contact Info</h5>
                    <div className="media">
                      <div className="media-left">
                        <i class="fa fa-map-marker"></i>
                      </div>
                      <div className="media-body">
                        <p>Address</p>
                        <span>{`${address.street_one}, ${address.city}, ${address.state} ${address.zip}`}</span>
                      </div>
                    </div>
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-phone" />
                      </div>
                      <div className="media-body">
                        <p>Phone</p>
                        <a href={`tel:${contact.phone}`}>{`+1 (${contact.phone.slice(0, 3)}) ${contact.phone.slice(3, 6)} ${contact.phone.slice(6)}`}</a>
                      </div>
                    </div>
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-envelope" />
                      </div>
                      <div className="media-body">
                        <p>Email</p>
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pd-top-90">
            <div className="col-lg-9">
              <div className="property-info border-bottom-yellow padding-bottom-20">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="single-property-info">
                      <h5>Bedrooms</h5>
                      <p><i className="fa fa-bed" />{selectedUnit&&selectedUnit.node.bedrooms}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="single-property-info">
                      <h5>Bathrooms</h5>
                      <p><i className="fa fa-bath" />{selectedUnit&&selectedUnit.node.bathrooms}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="single-property-info">
                      <h5>Area</h5>
                      <p><img src={ sqftIcon }alt="sqft icon" />{selectedUnit&&selectedUnit.node.sqft} sq. ft.</p>
                    </div>
                  </div>
                </div>
              </div>
              {selectedUnit.amenities ?
                <div className="property-news-single-card border-bottom-yellow padding-top-20">
                  <h4>Amenities</h4>
                  <div className="row">
                    {selectedUnit.amenities.map((amenity, i) => 
                        <div key={i} className="col-sm-4 mb-2">
                          <ul className="rld-list-style mb-3 mb-sm-0">
                            <li><i className="fa fa-check margin-top-10" />{amenity}</li>
                          </ul>
                        </div>
                    )}
                  </div>
                </div>
              : (locationAmenities.length > 0) &&
                <div className="property-news-single-card border-bottom-yellow padding-top-20">
                  <h4>Amenities</h4>
                  <div className="row">
                    {
                      locationAmenities.map((amenity, i) => 
                        <div key={i} className="col-sm-4 mb-2">
                          <ul className="rld-list-style mb-3 mb-sm-0">
                            <li><i className="fa fa-check margin-top-10" />{amenity}</li>
                          </ul>
                        </div>
                    )}
                  </div>
                </div>

              }
            </div>
          </div>
        </div>
        {selectedUnit && selectedUnit.floorPlan &&
          <div className="bg-gray">
            <div className="container">
              <div className="row pd-top-60">
                <div className="col-lg-9">
                  <div className="property-news-single-card border-bottom-yellow">
                    <h4>{selectedUnit.floorPlan.node.type}</h4>
                    <div className="thumb" style={{textAlign: "center"}}>
                      <img src={floorPlanPic} alt="floorplan" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
          <div className="container">
            <div className="row pd-top-60">
              <div className="col-lg-9">
                <div className="property-news-single-card container">
                  <h4>Location</h4><br />
                  <MapContainer lat={location.lat} lng={location.lng} name={location.name} width="10" height="100"/>
                  <br />
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default PropertyDetails;