import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button, Divider, Header, Label , Popup} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'
import moment from "moment";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { optionsState, formatPhoneNumber } from '../../utils/constants.js'
function Personal(props) {
    const [disableMiddle, setDisableMiddle] = useState(false);
    const eighteenYearsAgo = new Date();
    const [DOB, setDOB] = useState(moment().subtract(18, 'years'));
    const [vehicleAdd, setVehicleAdd] = useState(false);
    const [PersonalInfo, setPersonalInfo] = useState({
        Car: []
    });
    const [vehicles, setVehicles] = useState([

    ]);

    // Effect Handler to Local Storage
    useEffect(() => {
        if (performance.navigation.type === 1) {
            const savedData = localStorage.getItem('PersonalInfo')
            const DOB = localStorage.getItem('DOB')
            DOB && setDOB(DOB)
            savedData && setPersonalInfo(JSON.parse(savedData))
        } else {
            const savedData = localStorage.getItem('PersonalInfo')
            const DOB = localStorage.getItem('DOB')
            DOB && setDOB(DOB)
            savedData && setPersonalInfo(JSON.parse(savedData))
        }
    }, []);
    // Effect handler to set local storage
    useEffect(() => {
        const itemToSave = JSON.stringify(PersonalInfo)
        localStorage.setItem('DOB', DOB)
        if (Object.keys(PersonalInfo).length !== 0) {
            localStorage.setItem('PersonalInfo', itemToSave)
        }
    }, [PersonalInfo]);


    const addVehiclesLocation = () => {
        const newObj = {
            "make": '',
            "model": '',
            "year": '',
            "color": '',
            "license": ''
        }
        if (!PersonalInfo.Car || PersonalInfo.Car.length == 0) {
            setPersonalInfo(prev => ({
                ...prev,
                Car: [newObj]
            }))
        }
        else {
            setPersonalInfo({
                ...PersonalInfo,
                Car: [...PersonalInfo.Car, newObj]
            })

        }
    }


    const removeVehiclesLocation = (index) => {
        var { Car } = PersonalInfo
        var newCharges = PersonalInfo.Car.filter((charge, index2) => index2 !== index);
        Car = newCharges
        setPersonalInfo(prev => ({
            ...prev,
            Car: Car
        }))
        // setOccupantInfo({ Occupants })
        if (Car.length == 0) {
            setVehicleAdd(false)
        }
    }

    const handleChangeCar = (e, index, name) => {
        const { Car } = PersonalInfo;
        Car[index] = {
            ...Car[index],
            [name]: e.target.value
        };
        setPersonalInfo(prev => ({
            ...prev,
            Car: Car
        }))
    };

    useEffect(() => {
        if (disableMiddle == true) {
            props.setDataObject(prevState => ({
                ...prevState,
                ["middleName"]: ''
            }));

        }
    }, [disableMiddle]);

    const handleChange = e => {
        const { name, value } = e.target;
        setPersonalInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    

    const handleChangeSelect = (name, value) => {
        // const { name, value } = e.target;
        console.log(name, value)
        setPersonalInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const Stepper = () => {
        props.setDataObject(prevState => ({ ...prevState, Page5: PersonalInfo }))
        props.setAppStep(6)
    }
    const stDate = (e) => {
        setDOB(e)
        setPersonalInfo(prevState => ({
            ...prevState,
            ["dateOfBirth"]: e
        }));
    }

    const disabledCheck = () => {
        if (!DOB) {
            return true
        }
        if (!PersonalInfo.idNum) {
            return true
        }
        if (PersonalInfo.idNum.trim().length > 9 || PersonalInfo.idNum.trim().length < 9) {
            return true
        }
        if (PersonalInfo.idNum.trim().toString() === '123456789' || PersonalInfo.idNum.trim().toString() === '666666666' ) {
            return true
        }
        if (PersonalInfo.idNum.trim().toString() === "000000000"  ) {
            return true
        }
        if (PersonalInfo && PersonalInfo.Car && PersonalInfo.Car.length >= 1) {
            if (PersonalInfo.Car.every(item => item.license)) {
                return false
            }
            else return true
        }
        else return false
    }

    function format_ssn(el) {
        if (el) {
            var numbers = el.replace(/[^0-9]/g, '');
            if (numbers.length == 9) {
                el = numbers.substr(0, 3) + '-' + numbers.substr(3, 2) + '-' + numbers.substr(5, 4);
                return el
            }

        }
    }

    return (
        <>
            <div>
                <h1 class="section__heading">Personal information</h1>

                <Form>
                    <Form.Field required >
                        <label>Date of Birth</label>
                        <Flatpickr options={{
                            disabled: [
                                {
                                    from: moment().subtract(18, 'years'),
                                    to: moment()
                                },
                                
                            ], 
                            maxDate: eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18) ,
                            minDate: eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 81) 
                        }} 
                            value={DOB && moment(DOB).format('MM/DD/YYYY')} onChange={([date]) => {
                                    stDate(date);
                                }} />
                    </Form.Field>
                    <Form.Field required>
                        <label>SSN <Popup content='Valid 9 digit SSN only. 000-00-0000, 666-66-6666, 123-45-6789 are not valid' trigger={<Icon size="tiny" circular name='question' />} /></label>
                        <input maxLength="9" minlength="9" onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} name="idNum" value={format_ssn(PersonalInfo.idNum)} placeholder='SSN' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Drivers License</label>
                        <input disabled={disableMiddle} value={PersonalInfo.driversLic} name="driversLic" placeholder='Drivers License' onChange={handleChange} />
                    </Form.Field>
                    <Form.Select
                        fluid
                        search
                        label='State'
                        name="licenseState"
                        options={optionsState}
                        value={PersonalInfo.licenseState}
                        placeholder="State License"
                        onChange={(e, { value }) => handleChangeSelect("licenseState", value)}
                    />

                    <Divider />
                    <Header>Emergency Contact</Header>
                    <Form.Field>
                        <label>Name</label>
                        <input name="emergencyName" placeholder='Name' value={PersonalInfo.emergencyName} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Address</label>
                        <input name="emergencyAddress" placeholder='Address' value={PersonalInfo.emergencyAddress} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Relationship</label>
                        <input placeholder='Relationship' name="emergencyRelationship" value={PersonalInfo.emergencyRelationship} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Phone</label>
                        <input maxLength="10" onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} placeholder='Phone' name="emergencyPhone" value={formatPhoneNumber(PersonalInfo.emergencyPhone)} onChange={handleChange} />
                    </Form.Field>
                    <Divider />
                    <Header>I have one or more vehicles</Header>
                    {/* <Checkbox name="noMiddle" label='I have one or more vehicles' checked={vehicleAdd} onChange={() => setVehicleAdd(!vehicleAdd ? true : false)} /> */}

                    {
                        PersonalInfo && PersonalInfo.Car && PersonalInfo.Car.map((app, index) => {
                            return (
                                <>
                                    <Form>
                                        <Header>Vehicle</Header>
                                        <Form.Field>
                                            <label>Make</label>
                                            <input name={`make`} value={app.make} placeholder='Make' onChange={(e) => handleChangeCar(e, index, "make")} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Model</label>
                                            <input name={`model`} value={app.model} placeholder='Model' onChange={(e) => handleChangeCar(e, index, "model")} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Year</label>
                                            <input name={`year`} value={app.year} placeholder='Year' onChange={(e) => handleChangeCar(e, index, "year")} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Color</label>
                                            <input name={`color`} value={app.color} placeholder='Color' onChange={(e) => handleChangeCar(e, index, "color")} />
                                        </Form.Field>
                                        <Form.Field required>
                                            <label>License</label>
                                            <input name={`license`} value={app.license} placeholder='License' onChange={(e) => handleChangeCar(e, index, "license")} />
                                        </Form.Field>
                                    </Form>
                                    <Label as={'button'} onClick={() => removeVehiclesLocation(index)}>
                                        <Icon name='minus' /> Remove Vehicle
                                    </Label>
                                    <Divider />
                                </>
                            )
                        })
                    }


                    <>
                        <Label as={'button'} onClick={() => addVehiclesLocation()}>
                            <Icon name='add' /> Add Vehicle
                        </Label>
                    </>
                </Form>
                <br></br>
                <div style={{ float: 'right' }}>
                    <Button icon labelPosition='left' onClick={() => props.setAppStep(4)}>
                        <Icon name='left arrow' />
                        Previous
                    </Button>
                    <Button icon labelPosition='right' disabled={disabledCheck()} onClick={() => Stepper()}>
                        Save & Next
                        <Icon name='right arrow' />
                    </Button>
                </div>

            </div>
        </>
    );
}

export default Personal